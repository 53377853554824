import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { HiMenu } from 'react-icons/hi';

import './index.css'

export function Navbar() {
    const [toggleMenu, setToggleMenu] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)


    const toggleNav = () => {
        setToggleMenu(!toggleMenu)
    }

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }

    }, [])

    return (
        <nav>
            {(toggleMenu || screenWidth > 800) && (
                <ul className='list'>
                    <li className="items" onClick={toggleNav}>
                        <HashLink smooth to="/home#page-top">
                            Home
                        </HashLink>
                    </li>
                    <li className="items" onClick={toggleNav}>
                        <HashLink smooth to="/home#about">
                            About Us
                        </HashLink>
                    </li>
                    <li className="items" onClick={toggleNav}>
                        <HashLink smooth to="/home#testimonials">
                            Testimonials
                        </HashLink>
                    </li>
                    <li className="items" onClick={toggleNav}>
                        <HashLink smooth to="/home#team">
                            Our Staff
                        </HashLink>
                    </li>
                    <li className="items" onClick={toggleNav}>
                        <HashLink smooth to="/home#services">
                            Our Services
                        </HashLink>
                    </li>
                    <li className="items" onClick={toggleNav}>
                        <HashLink smooth to="/home#contact">
                            Contact
                        </HashLink>
                    </li>
                    <li className="items" onClick={toggleNav}>
                        <Link to="/insurance">Insurances</Link>
                    </li>
                    <li className="items" onClick={toggleNav}>
                        <Link to="/referrals">Referrals</Link>
                    </li>
                    <li className="items" onClick={toggleNav}>
                        <Link to="/careers">Careers</Link>
                    </li>
                    {/* <li className="items" onClick={toggleNav}>
                        <Link to="/forms">Staff Login</Link>
                    </li> */}
                </ul>
            )}

            <div onClick={toggleNav} className="btn"><HiMenu size={20} color='#303580' /></div>
        </nav>
    )
}